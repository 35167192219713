<template>
  <div class="viewer-container">
    <SettinsComponent :create-avatar="createAvatar"/>
    <div ref="cameraPositionDisplay" class="camera-position"></div>
    <div id="container" ref="container"></div>
    <div ref="tooltip" class="tooltip" v-show="showTooltip">
      <!-- Tooltip content goes here -->
      <p>{{ tooltipContent }}</p>
    </div>
    <HideableBottomList ref="bottomList" v-if="mainProduct && !reloads" :toggled="listToggled" :product-selected="productSelected"
                        :product-name="mainProduct.name" :recommended="recommended_products"></HideableBottomList>
  </div>
</template>
<script>
import SettinsComponent from "./SettinsComponent.vue";
import HideableBottomList from "./HideableBottomList.vue";

import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import {GLTFLoader} from 'three/addons/loaders/GLTFLoader.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
//import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import {markRaw} from 'vue';


export default {
  components: {
    SettinsComponent,
    HideableBottomList
  },
  props: {
    body: {
      type: String,
      default: null
    },
    avatarGender: {
      type: String,
      default: null
    },
    slug: {
      type: String,
      default: 'zvztrklbs-01'
    },
    bodyType: {
      type: String,
      default: 'body_1'
    },
    createAvatar: Function,
    product: Object,
    productlist: Array
  },
  data() {
    return {
      reloads: false,
      scene: null,
      loadLogo: null,
      billboard: null,
      billboardContext: null,
      billboardCanvas: null,
      billboardVisible: false,
      renderer: null,
      camera: null,
      mixer: null,
      TWEEN: null,
      controls: null,
      animationGroup: null,
      clock: null,
      currentAvatar: null,
      walkAction: null,
      bodyModel: null,
      productModels: [],
      productDots: [],
      showTooltip: false,
      tooltipContent: '',
      mouse: null,
      raycaster: null,
      rayLine: null,
      rayLineMaterial: null,
      mainProduct: null,
      products: [],
      recommended_products: [],
      cameraPositions: {
        init: {
          x: 0,
          y: 3,
          z: 5,
          controlX: 0,
          controlY: .5,
          controlZ: 0
        },
        default: {
          x: 0.4,
          y: 1.25,
          z: 3.78,
          controlX: 0,
          controlY: 0.4,
          controlZ: 0
        },
        face: {
          x: 0.25,
          y: 1.18,
          z: 2.80,
          controlX: 0,
          controlY: 0.8,
          controlZ: 0
        },
        panel: {
          x: -0.5,
          y: 1.38,
          z: 3,
          controlX: 0.2,
          controlY: 1,
          controlZ: 0.8
        }
      }
    };
  },
  async mounted() {
    this.addLogo()
    this.products = []
    this.mainProduct = this.product;
    Object.keys(this.productlist).forEach(key => {
      const pr = this.productlist[key];
      pr.loaded = false;
      pr.loading = false;

      this.recommended_products.push(pr);
    });
    this.initThree();
    this.loadBody();
    this.billboard = this.createBillboard();
    window.addEventListener('click', this.onMouseMove, false);
  },
  beforeUnmount() {
    // Cleanup to prevent memory leaks
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('click', this.onMouseMove);

    if (this.renderer) {
      this.renderer.dispose();
    }
  },
  methods: {
    animateCamera(newPosition, newControlsTarget) {
      new TWEEN.Tween(this.camera.position)
          .to({x: newPosition.x, y: newPosition.y, z: newPosition.z}, 1000) // duration in ms
          .easing(TWEEN.Easing.Quadratic.Out) // easing function
          .start();

      // Animate controls target
      new TWEEN.Tween(this.controls.target)
          .to({x: newControlsTarget.x, y: newControlsTarget.y, z: newControlsTarget.z}, 1000)
          .easing(TWEEN.Easing.Quadratic.Out)
          .onUpdate(() => this.controls.update()) // update controls on each tween update
          .start();
    },
    productSelected(product) {
      if (product.loaded) {
        this.removeProduct(product);
      } else {
        this.findProductsByBodyType(product.body_part, product.priority);
        this.loadProduct(product);
      }
    },
    findProductsByBodyType(body_part, priority) {
      console.log('clearing if possingle', this.productModels, body_part, priority)
      Object.entries(this.productModels).forEach((pro) => {
        const product = this.products[pro[0]];
        console.log('checkin', product)
        console.log('body part', body_part, 'priority', priority, product);
        if ((product.body_part === body_part && product.priority === priority) ||
            (body_part === "full body" && (product.body_part === "üst beden" || product.body_part === "alt beden") && priority == product.priority) ||
            product.body_part === "full body" && (body_part === "üst beden" || body_part === "alt beden") && priority === product.priority) {
          console.log('in check');

          this.productSelected(product);
        }
      });
    },
    getModelUrl(product) {
      console.log('get model url', product, this.bodyType)
      if (this.bodyType !== 'body_1') {
        const filter = this.bodyType.split('_')[1]
        console.log('body id', filter);

        const filtered_model = product.body_models.filter((model) => {
          if (model.body === filter) {
            return model
          }
        })
        const result = `https://fashionmakerapi.arftower.com/api/posts/photo/${filtered_model[0].url}`
        console.log('filtered', filtered_model)
        return result
      } else {
        return product.models[0]
      }

    },
    setCamera(pos) {
      console.log('camera set', pos);
      const position = this.cameraPositions[pos];
      this.animateCamera(
          new THREE.Vector3(position.x, position.y, position.z),
          new THREE.Vector3(position.controlX, position.controlY, position.controlZ)
      );
    },
    loadProduct(product) {
      product.loading = true;
      this.products[product._id] = product;

      console.log('load pro', product);
      this.loadModel(this.getModelUrl(product), (gltf, size) => {
        const model = gltf.scene;
        const sceneModel = model //this.replaceSkeleton(this.bodyModel, model);
        this.scene.add(sceneModel);
        //const skeletonHelper = this.addSkeletonHelper(sceneModel, this.scene);
        //console.log('product', sceneModel, skeletonHelper);
        sceneModel.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = true;
            object.material.envMapIntensity = 0.3;
            //object.material.polygonOffset = true;
            //object.material.polygonOffsetFactor = -10; // You might need to adjust this value
            //object.material.polygonOffsetUnits = -2;
          }
        });

        this.animationGroup.add(model);
        this.productModels[product._id] = model;
        console.log('sizes', size);
        this.productDots[product._id] = this.addDot(product, model, size);
        //console.log('model productDots', this.productDots)

        product.loading = false;
        product.loaded = true;
        this.products[product._id] = product;
      })
    },
    listToggled(value) {
      if (!value && !this.billboardVisible) {
        this.setCamera('face');
      } else if (value) {
        this.setCamera('default');
        if (this.billboardVisible) {
          this.hideBillboard(this.billboard);
        }
      }
    },

    removeProduct(product) {
      const model = this.productModels[product._id];
      if (model) {
        this.scene.remove(model);
        this.scene.children = this.scene.children.filter(child => child.uuid !== model.uuid);
        console.log('removing model', this.scene.children);

        // Optionally dispose of the geometry, materials, and textures to free up memory
        model.traverse((object) => {
          if (object.isMesh) {
            console.log('object', object.isMesh, object)
            object.geometry.dispose();
            object.material.dispose();
            if (object.material.map) object.material.map.dispose();
          }
        });

        delete this.productModels[product._id];
        this.removeDot(product._id);
        //  delete this.products[product._id];

        product.loading = false;
        product.loaded = false;
        this.products[product._id] = product;
        this.scene.updateMatrixWorld();
        console.log('removed product', model, this.scene);

      }
    },
    loadBody() {
      this.loadModel(this.current_body, (gltf) => {
        const model = gltf.scene;
        model.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = true;
            object.material.envMapIntensity = 0.3;
          }
        });
        this.bodyModel = model;
        this.animationGroup.add(model);
        this.hideLogo();
        this.scene.add(model);
        this.setCamera('face');
        this.loadProduct(this.mainProduct);
        //this.$refs.bottomList.openPanel();
      })
    },
    addLogo() {

      const self = this;
      this.loadModel('/M.glb', (gltf) => {
        const model = gltf.scene;
        const clip = self.filterAnimation(gltf.animations[0]);
        const action = self.mixer.clipAction(clip);

        action.play();
        model.scale.set(0, 0, 0);
        model.position.y = 0.5;
        this.loadLogo = model;
        this.scene.add(model);
        this.showLogo();
      })

    },

    showLogo() {
      new TWEEN.Tween(this.loadLogo.scale)
          .to({x: 0.25, y: 0.25, z: 0.25}, 1000) // Scale to normal size over 1 second
          .easing(TWEEN.Easing.Exponential.Out) // Elastic easing for a pop effect
          .start();
      this.billboardVisible = true;
    },

    hideLogo() {
      new TWEEN.Tween(this.loadLogo.scale)
          .to({x: 0, y: 0, z: 0}, 1000) // Shrink to zero size over 1 second
          .easing(TWEEN.Easing.Exponential.In) // Elastic easing for a smooth collapse
          .start();
      this.billboardVisible = false;
    },

    initThree() {
      const container = this.$refs.container;

      this.rayLineMaterial = markRaw(new THREE.LineBasicMaterial({color: 0xff0000}));

      this.clock = markRaw(new THREE.Clock());
      this.mouse = markRaw(new THREE.Vector2());
      this.raycaster = markRaw(new THREE.Raycaster());
      // Camera
      this.camera = markRaw(new THREE.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 1, 1000));
      this.camera.lookAt(new THREE.Vector3(-100, 1, 4)); // Look at the center of the model, adjust Y value as needed
      this.camera.near = 0.1;
      this.camera.far = 1000;
      this.animationGroup = markRaw(new THREE.AnimationObjectGroup());

      this.mixer = markRaw(new THREE.AnimationMixer(this.animationGroup));
      // Scene

      this.scene = markRaw(new THREE.Scene());
      this.scene.background = new THREE.Color(0xfcfcfc);
      this.scene.fog = new THREE.Fog(0xcccccc, 30, 90);

      // Lights
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff); // warm sky color, soft ground color
      hemiLight.position.set(10, 20, 0);
      this.scene.add(hemiLight);
      // Lights
      const hemiLight2 = new THREE.HemisphereLight(0xffe5cc, 0xffffff); // warm sky color, soft ground color
      hemiLight2.position.set(-10, -20, 0);
      this.scene.add(hemiLight2);


      const dirLight = new THREE.DirectionalLight(0xffffff); // warm sunlight color
      dirLight.position.set(-3, 3, 3);
      dirLight.castShadow = true;
      dirLight.shadow.camera.top = 2;
      dirLight.shadow.camera.bottom = -2;
      dirLight.shadow.camera.left = -2;
      dirLight.shadow.camera.right = 2;
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 40;
      this.scene.add(dirLight);

      // Adding a spotlight to illuminate the model's face
      const spotLight = new THREE.SpotLight(0xffffff, 1); // Warm color, high intensity
      spotLight.position.set(0, 2, 2); // Position the light in front of and above the model
      spotLight.target.position.set(0, 0, 0); // Adjust target position to focus on the model's face
      spotLight.angle = Math.PI / 4; // Adjust the cone angle for wider or narrower light
      spotLight.penumbra = 0.5; // Soften the edges of the spotlight's beam
      this.scene.add(spotLight);
      this.scene.add(spotLight.target);
      const faceLight = new THREE.HemisphereLight(0xcccccc, 0xffffff, 0.5); // Warm colors, lower intensity
      faceLight.position.set(0, 5, 5); // Position to best illuminate the model's face
      this.scene.add(faceLight);
      // Renderer
      this.renderer = new THREE.WebGLRenderer({antialias: true});

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      // Set up rotation limits
      this.controls.maxPolarAngle = Math.PI / 1.85; // 45 degrees
      this.controls.minPolarAngle = 0; // 45 degrees

      // Set up zoom limits
      this.controls.minDistance = 0; // Minimum zoom distance
      this.controls.maxDistance = 10; // Maximum zoom distance

      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      this.renderer.outputColorSpace = THREE.SRGBColorSpace;
      this.renderer.shadowMap.enabled = true;

      container.appendChild(this.renderer.domElement);
      this.setCamera('init');

      const self = this;
      this.loadModel("/idle.glb", function (gltf) {
        const clip = self.filterAnimation(gltf.animations[0]);
        const action = self.mixer.clipAction(clip);
        self.walkAction = action;
        self.walkAction.play();
      });

      /*new RGBELoader().load(
        "/sky.hdr",
        (texture) => {
          texture.mapping = THREE.EquirectangularReflectionMapping;
          this.scene.environment = texture;
          this.scene.background = texture;
        }
      );*/

      window.addEventListener('resize', this.onWindowResize);
      /*
      const textureLoader = new THREE.TextureLoader();
      const groundTexture = textureLoader.load('/texture/sand.jpg');

      groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping;

      groundTexture.repeat.set(8, 8); // Adjust these numbers as needed

      const groundMaterial = new THREE.MeshPhongMaterial({
        map: groundTexture, // Apply the texture as the map
        depthWrite: false
      });
*/
      const groundRadius = 50; // Set the radius of the circle
      const groundSegments = 32; // More segments make the circle smoother
      const groundGeometry = new THREE.CircleGeometry(groundRadius, groundSegments);

      const groundMaterial = new THREE.MeshPhongMaterial({
        color: 0xfefefe, // Set the color to white
        depthWrite: false
      });
      const ground = new THREE.Mesh(groundGeometry, groundMaterial);
      ground.rotation.x = -Math.PI / 2; // Rotate the ground to be horizontal
      ground.receiveShadow = true; // Let the ground receive shadows
      ground.position.y += 0.045; // Move the ground up by 0.02 units
      this.scene.add(ground);

      this.animate();
    },
    updateCameraPositionDisplay() {
      const pos = this.camera.position;
      const text = `X: ${pos.x.toFixed(2)}, Y: ${pos.y.toFixed(2)}, Z: ${pos.z.toFixed(2)}`;
      this.$refs.cameraPositionDisplay.textContent = text;
    },
    onWindowResize() {
      const container = this.$refs.container;
      this.camera.aspect = container.clientWidth / container.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(container.clientWidth, container.clientHeight);
    },
    animate() {
      requestAnimationFrame(this.animate);
      // Add animation or interaction logic here
      const delta = this.clock.getDelta();
      if (this.mixer) {
        this.mixer.update(delta);
      }
      this.controls.update();
      //this.updateCameraPositionDisplay();
      TWEEN.update(); // Add this line to update TWEEN

      this.renderer.render(this.scene, this.camera);
    },
    addBoundingBox(object, scene) {
      const bbox = new THREE.Box3().setFromObject(object);
      const bboxHelper = new THREE.Box3Helper(bbox, 0xff0000); // Red bounding box
      scene.add(bboxHelper);
    },
    loadModel(url, callback) {
      const loader = new GLTFLoader();
      loader.load(
          url,
          (gltf) => {
            const model = gltf.scene;
            const box = new THREE.Box3().setFromObject(model);
            const size = new THREE.Vector3();
            box.getSize(size);

            callback(gltf, size);
          },
          undefined,
          (error) => {
            console.error('An error occurred while loading the GLB model:', error);
          }
      )
      // Add other methods like loadAvatar, filterAnimation, etc., as needed
    },
    filterAnimation(animation) {
      animation.tracks = animation.tracks.filter((track) => {
        const name = track.name;
        return name.endsWith("Hips.position") || name.endsWith(".quaternion");
      });
      return animation;
    },
    createBillboard() {
      // Define the size of the billboard
      const width = 1;  // Adjust width as needed
      const height = 2; // Adjust height as needed
      const borderThickness = 0.05; // Thickness of the border for top and bottom
      const sideBorderThickness = 0.05; // Thicker border for the sides

      // Create the billboard geometry
      const billboardGeometry = new THREE.BoxGeometry(width, height, borderThickness);

      // Create materials for the billboard
      const billboardMaterial = new THREE.MeshBasicMaterial({color: 0xeeeeee}); // Softened white panel
      const borderMaterial = new THREE.MeshBasicMaterial({color: 0x222222}); // Softened black border

      // Create the billboard mesh
      const billboard = new THREE.Mesh(billboardGeometry, billboardMaterial);

      // Create borders (slightly larger and thicker on the sides)
      const borderGeometry = new THREE.BoxGeometry(width + 2 * sideBorderThickness, height + 2 * borderThickness, borderThickness);
      const border = new THREE.Mesh(borderGeometry, borderMaterial);
      border.position.setZ(-borderThickness / 2); // Adjust to align with the back of the billboard

      // Group the billboard and border
      const billboardGroup = new THREE.Group();
      billboardGroup.add(billboard);
      billboardGroup.add(border);

      // Create a canvas and context for the billboard texture
      this.billboardCanvas = document.createElement('canvas');
      this.billboardCanvas.width = 256; // Adjust as needed
      this.billboardCanvas.height = 512; // Adjust as needed
      this.billboardContext = this.billboardCanvas.getContext('2d');

      // Create an initial texture (which can be updated later)
      const billboardTexture = new THREE.CanvasTexture(this.billboardCanvas);
      billboardMaterial.map = billboardTexture;
      // Set to a fixed position in the scene
      // Move it right (x) and backward (z) by 2 units
      billboardGroup.position.set(1.1, 1.1, -0.5); // Adjust x, y, z coordinates as needed

      // Rotate the group 45 degrees around the y-axis
      billboardGroup.rotation.y = -Math.PI / 8; // 45 degrees in radians

      billboardGroup.scale.set(0, 0, 0);
      this.billboardVisible = false;
      // Add billboard group to the scene
      this.scene.add(billboardGroup);
      billboardGroup.traverse(function (object) {
        if (object.isMesh) {
          object.castShadow = true;
          object.receiveShadow = true;
          object.material.envMapIntensity = 0.3;
        }
      });
      return billboardGroup;
    },
    loadTexture(url) {
      return new Promise((resolve, reject) => {
        new THREE.TextureLoader().load(url, resolve, undefined, reject);
      });
    },
    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
      const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      return {width: srcWidth * ratio, height: srcHeight * ratio};
    },
    async updateBillboardTexture(product) {
      console.log('product texture', product)
      // Clear the canvas
      this.billboardContext.clearRect(0, 0, this.billboardCanvas.width, this.billboardCanvas.height);
      // Set the canvas background to white
      this.billboardContext.fillStyle = '#FFFFFF'; // White color
      this.billboardContext.fillRect(0, 0, this.billboardCanvas.width, this.billboardCanvas.height); // Fill the canvas with white

      // Load the new product image
      let img;
      if (product.images.length > 1) {
        img = product.images[1];
      } else {
        img = product.images[0];
      }
      let texture;
      try {
        texture = await this.loadTexture(img);
      } catch (error) {
        console.error('Error loading texture:', error);
        // Handle error (e.g., set a default texture)
      }
      if (texture) {
        const imgWidth = texture.image.width;
        const imgHeight = texture.image.height;

        // Calculate the best fit dimensions while maintaining the aspect ratio
        const {
          width,
          height
        } = this.calculateAspectRatioFit(imgWidth, imgHeight, this.billboardCanvas.width - 20, this.billboardCanvas.height - 240);

        // Center the image on the canvas
        const x = (this.billboardCanvas.width - width) / 2;
        const y = (this.billboardCanvas.height - 240 - height) / 2 + 10;

        this.billboardContext.drawImage(texture.image, x, y, width, height);
      }
      // Draw the new image on the canvas
      // Add new product details as text
      this.billboardContext.fillStyle = '#000000';
      this.billboardContext.font = '13px Poppins';
      let yPos = this.billboardCanvas.height - 210;
      const nameChunks = this.splitTextIntoLines(product.name, 36);
      nameChunks.forEach(chunk => {
        this.billboardContext.fillText(chunk, 10, yPos);
        yPos += 15; // Increment the Y position for the next line; adjust as needed based on font size
      });
      const descriptionChunks = this.splitTextIntoLines(product.description, 36);

      // Set the font for the description
      this.billboardContext.font = 'ExtraLight 8px Poppins'; // Adjust font size as needed

      this.billboardContext.fillStyle = '#333333';
      // Render each chunk of the description
      descriptionChunks.forEach(chunk => {
        this.billboardContext.fillText(chunk, 10, yPos);
        yPos += 15; // Increment the Y position for the next line; adjust as needed based on font size
      });

      yPos += 10;
      this.billboardContext.font = 'Bold 15px Poppins';
      //this.billboardContext.fillText(`${product.price} TL`, 10, yPos);

      // Update the texture
      this.billboard.children[0].material.map.needsUpdate = true;
    },

    splitTextIntoLines(text, maxLineLength) {
      const words = text.split(' ');
      const lines = [];
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        if (currentLine.length + words[i].length + 1 <= maxLineLength) {
          currentLine += ' ' + words[i];
        } else {
          lines.push(currentLine);
          currentLine = words[i];
        }
      }
      lines.push(currentLine); // Push the last line

      return lines;
    },

    showBillboard(billboardGroup) {
      new TWEEN.Tween(billboardGroup.scale)
          .to({x: 1, y: 1, z: 1}, 1000) // Scale to normal size over 1 second
          .easing(TWEEN.Easing.Exponential.Out) // Elastic easing for a pop effect
          .start();
      this.billboardVisible = true;
    },

    hideBillboard(billboardGroup) {
      new TWEEN.Tween(billboardGroup.scale)
          .to({x: 0, y: 0, z: 0}, 1000) // Shrink to zero size over 1 second
          .easing(TWEEN.Easing.Exponential.In) // Elastic easing for a smooth collapse
          .start();
      this.billboardVisible = false;
    },

    handleClickOnDot() {
      // Retrieve the billboard associated with the clicked dot
      // Check if the billboard exists and is not already visible
      if (!this.billboardVisible) {
        this.showBillboard(this.billboard);
      }

      this.$refs.bottomList.closePanel();
      this.setCamera('panel');
    },

    handleHover(obj) {
      // Get or create the line and panel associated with the dot
      this.updateBillboardTexture(obj.userData.product);
      this.handleClickOnDot();
    },
    onMouseMove(event) {
      // Convert mouse position to normalized device coordinates (-1 to +1)
      const rect = this.$refs.container.getBoundingClientRect();
      this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      // Update the picking ray with the camera and mouse position
      this.raycaster.setFromCamera(this.mouse, this.camera);

      // Check for intersections
      const dots = Object.values(this.productDots);
      const intersects = this.raycaster.intersectObjects(dots);

      if (intersects.length > 0 && intersects[0].object.userData.product) {
        //console.log("Intersects:", intersects); // Log to see what's being intersected
        this.handleHover(intersects[0].object);
      }
    },
    addDot(product, model, size) {
      console.log('model size', size, product.body_part)
      const sphereGeometry = new THREE.SphereGeometry(0.04, 32, 32); // Sphere radius and segment counts
      const sphereMaterial = new THREE.MeshBasicMaterial({color: 0x333333});
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.userData.product = product;

      switch (product.body_part.trim()) {
        case 'üst beden': // Upper body
          console.log('üst bed');
          sphere.position.set(.33, 1.45, 0);
          break;
        case 'alt beden': // Lower body
          sphere.position.set(.33, .8, 0);
          break;
        case 'kafa': // Head
          sphere.position.set(.33, 1.55, 0);
          break;
        case 'ayak':
        case 'ayakkabı': // Foot
          sphere.position.set(.33, 0, 0);
          break;
        case 'el': // Hand
          sphere.position.set(.33, 1, 0);
          break;
        case 'full body':
          sphere.position.set(.33, 1, 0);
          break;
        default:
          sphere.position.set(.33, -1, 0);
          break;
      }

      this.scene.add(sphere);
      //this.addBoundingBox(sphere, this.scene);
      return sphere;
    },
    removeDot(productId) {
      // Get the dot (sphere) associated with the productId
      const dot = this.productDots[productId];
      console.log('dot', dot);
      // Check if the dot exists
      if (dot) {
        // Remove the dot from the scene
        this.scene.remove(dot);
        this.scene.children = this.scene.children.filter(child => child.uuid !== dot.uuid);
        // Optional: Dispose of the geometry and material if they're not shared
        dot.geometry.dispose();
        dot.material.dispose();

        // Remove the reference from the productDots object
        delete this.productDots[productId];

        this.scene.updateMatrixWorld()
      } else {
        console.log('Dot not found for product ID:', productId);
      }
    }
  },
  computed: {
    current_body() {
      const gender = this.mainProduct.gender === 'erkek' ? 'male' : 'female'
      console.log('gender', gender);
      if (this.body && (gender === this.avatarGender)) {
        return this.body
      } else {
        return this.mainProduct.gender === 'erkek' ? '/models/male.glb' : '/models/female.glb'
      }
    }
  }
};
</script>
