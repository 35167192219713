<template>
  <div class="scene-wrapper">
    <div class="viewer-wrapper" v-if="mainProduct">
      <SceneViewer
        :productlist="products" 
        :product="mainProduct" 
        :body="userAvatarUrl" 
        :body-type="!userBodyId ? 'body_1': userBodyId" 
        :avatar-gender="avatarGender" 
        :create-avatar="setViewMode"
        v-if="viewMode === 'scene' && mainProduct"
        :slug="slug" 
      />
      <PoseAvatarComponent
          v-if="viewMode === 'sceneX' && mainProduct"></PoseAvatarComponent>
      <AvaturnViewer 
        :reset="resetConnection" 
        :avatar-loaded="avatarLoaded" 
        :go-back="setViewMode"
        v-if="viewMode === 'edit' && avaturnUrl" 
        :url="avaturnUrl" 
      />
      <AvatarList
      v-if="viewMode === 'list' && logger"  
      :avatars="logger.avatars" 
      :back="setViewMode"
      :base-view="viewWithBaseModel"
      :avatar-selected="avatarSelected"
      :delete-avatar="deleteAvatar"
      />
      <CreateAvatar 
        v-if="viewMode === 'create'"
        :go-back="setViewMode"
      />

      <CreateAvatarForm
      :completed="avatarCreated"
      :go-back="setViewMode"
      :uid="userId"
        v-if="viewMode === 'init'"
      />
      <div v-if="isLoading" class="spinner-wrapper2">
        <div class="spinner2"></div>
        <MatchyLogo3 />

      </div>
    </div>
  </div>
</template>

<script>

import CreateAvatarForm from "@/components/CreateAvatarForm.vue";
import CreateAvatar from "./components/CreateAvatar.vue";

import AvaturnViewer from "@/components/AvaturnViewer.vue";
import AvatarList from "@/components/AvatarList.vue";
import MatchyLogo3 from "@/components/MatchyLogo3.vue";
import PoseAvatarComponent from "@/components/PoseAvatarComponent.vue";
import SceneViewer from "@/components/SceneViewer.vue";

export default {
  name: 'App',
  components: {
    SceneViewer,
    PoseAvatarComponent,
    CreateAvatarForm,
    CreateAvatar,
    AvaturnViewer,
    AvatarList,
    MatchyLogo3
  },
  data() {
    return {
      viewMode: 'scene', // scene, edit, list, create, init
      slug: '',
      waitForRender: false,
      store_slug: null,
      logger: null,
      fetch: false,
      showScene: false,
      showAvaturn: false,
      isLoading: false,
      showAvatars: true,
      showLogo: false,
      userAvatars: [],
      mainProduct: null,
      products: [],
      token: 'PsP6tfiW-Au4JbD8QE9RVb8I6UDSWPpgP2JQ0xx07xs-mzzK2XDbv5EltHJyalPp1qNM_5vXNJgfcLqJdBMDUg'
    }
  },
  async mounted() {
    this.slug = this.getSlug();
    console.log('slug', this.slug);
    console.log('uid', this.userId);
    await this.fetchData();
    if (this.userId === null) {
      await this.createNewUser();
    } else {
      await this.getLogger(this.userId);
      await this.getUserAvatars();
      console.log('session', this.sessionId, this.userAvatarUrl, this.avatarGender, this.userBodyId);      
    }
  },
  computed: {
    avaturnUrl: {
      get() {
        // Retrieve the user_id from localStorage
        return this.$store.state.avaturnUrl || localStorage.getItem('avaturnUrl');
      },
      set(value) {
        // Set the user_id in localStorage
        this.$store.dispatch('setAvaturnUrl', value);
      }
    },
    userId: {
      get() {
        console.log('get uid', this.$store.state.userId, localStorage.getItem('userId'));
        // Retrieve the user_id from localStorage
        return this.$store.state.userId || localStorage.getItem('userId');
      },
      set(value) {
        // Set the user_id in localStorage
        this.$store.dispatch('setUserId', value);
      }
    },
    sessionId: {
      get() {
        // Retrieve the user_id from localStorage
        return this.$store.state.sessionId || localStorage.getItem('sessionId');
      },
      set(value) {
        this.$store.dispatch('setSessionId', value);
      }
    },
    userAvatarUrl: {
      get() {
        // Retrieve the user_id from localStorage
        return this.$store.state.userAvatarUrl || localStorage.getItem('userAvatarUrl');

      },
      set(value) {
        // Set the user_id in localStorage
        this.$store.dispatch('setUserAvatarUrl', value);
      }
    },
    userBodyId: {
      get() {
        // Retrieve the user_id from localStorage
        return this.$store.state.userBodyId || localStorage.getItem('userBodyId')
      },
      set(value) {
        // Set the user_id in localStorage
        this.$store.dispatch('setUserBodyId', value);
      }
    },
    avatarGender: {
      get() {
        // Retrieve the user_id from localStorage
        return this.$store.state.avatarGender || localStorage.getItem('avatarGender')
      },
      set(value) {
        // Set the user_id in localStorage
        this.$store.dispatch('setAvatarGender', value);
      }
    },
  },
  watch: {
    logger: {
      deep: true,
      handler() {
        console.log('logs', this.logger)
      }
    },
    viewMode() {
      console.log('viewMode', this.viewMode);
      if (this.viewMode === 'list') {
        this.getUserAvatars();
      }
    }
  },
  methods: {
    async avatarCreated(avatar_id) {
      await this.createNewSession(this.userId, avatar_id);
    },
    viewWithBaseModel() {
      this.userAvatarUrl = null;
      this.userBodyId = 'body_1';
      this.avatarGender = null;
      this.viewMode = 'scene';
    },
    setViewMode(mode) {
      if (mode === 'init' && this.userAvatars.length > 2) {
        console.log('not allowed')
      } else {
        this.viewMode = mode;
      }
    },
    async avatarSelected(avatar) {
        const avatar_id = avatar.id;
        await this.createNewSession(this.userId, avatar_id);
    },
    async resetConnection () {
      //localStorage.clear();

      if (this.userId === null) {
        await this.createNewUser();
      } else {
        await this.getUserAvatars();
        console.log('session', this.sessionId, this.userAvatarUrl, this.avatarGender, this.userBodyId);
        this.viewMode = 'list';
      }
    },
    async fetchData () {
      try {
        const response = await fetch(`https://fashionmakerapi.arftower.com/api/store/plugin?product=${this.slug}`);
        const data = await response.json();
        console.log('fetched', data)
        this.mainProduct = data.data.selected_product;
        data.data.products.map(product => {
          this.products[product._id] = product;
        })
        this.products[this.mainProduct._id] = this.mainProduct;
        this.store_slug = this.mainProduct.brand.slug;
        window.parent.postMessage('content-slid-out', '*');
        console.log('response', data, this.mainProduct, this.products);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    avatarLoaded(res) {
      this.userAvatarUrl = res.url;
      this.userBodyId = res.bodyId;
      this.avatarGender = res.gender;
      this.viewMode = 'scene';
    },
    createLog(action, log_txt) {
      const log = {
        action, 
        log: log_txt,
        store: this.store_slug
      }
      this.logger.logs.push(log);
    },
    async getLogger(user_id) {
      const response = await fetch(`https://fashionmakerapi.arftower.com/api/plugin-logs/${user_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log('get logger', response.ok)
        if (!response.ok) {
          this.createLogger(user_id);
        } else {
          const data = await response.json();
          this.logger = data.data;
          console.log('get logger result', this.logger);
        }
    },

    async updateLogger(user_id) {
      const payload = JSON.stringify(this.logger);
      const response = await fetch(`https://fashionmakerapi.arftower.com/api/plugin-logs/${user_id}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          },
          body: payload
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.getLogger(user_id);
        console.log('update logger result', data);
    },
    async createLogger(user_id) {
      const response = await fetch(`https://fashionmakerapi.arftower.com/api/plugin-logs`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            avaturn_user_id: user_id
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.logger = data.data;
        console.log('logger result', data);
    },
    async getUserAvatars() {
      try {
        console.log('userId', this.userId)
        const response = await fetch(`https://api.avaturn.me/api/v1/users/${this.userId}/avatars`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('user avatars', data);
        this.userAvatars = data;
        await this.processAvatars();
        //this.createLog('view', 'list_avatars');
        // Process the data as needed
      } catch (error) {
        console.error('Error making the API call:', error);
      }
    },
    async processAvatars() {
        const userAvatars = this.userAvatars; // Your array of user avatars
        this.logger.avatars = this.logger.avatars || []; // Initialize if not already an array
        let renderNeeded = false;
        let resetNeeded = false;
        console.log('loggin avatars', userAvatars, this.logger.avatars)
        for (const userAvatar of userAvatars) {
            // Check if userAvatar is already in logger.avatars
            const exists = this.logger.avatars.some(loggerAvatar => loggerAvatar.id === userAvatar.id);
            // If it doesn't exist, add it to logger.avatars
            if (!exists) {
                this.logger.avatars.push(userAvatar);
                await this.renderAvatarImage(userAvatar);
                renderNeeded = true;
            } else {
              const logAvatar = this.logger.avatars.filter(loggerAvatar =>loggerAvatar.id === userAvatar.id)[0];
              // If the avatar doesn't have a render_url, call renderAvatarImage
              if (!logAvatar.render_url) {
                  await this.renderAvatarImage(logAvatar);
                  renderNeeded = true;
              }
            }
        }
      if (renderNeeded || resetNeeded) {
            if (resetNeeded) {
              this.logger.avatars = this.userAvatars;
            }
            await this.updateLogger(this.userId);
            this.waitForRender = true;
            this.startRenderCheckInterval();
        }
    },
    async checkRenderUrl(url) {
        try {
            const response = await fetch(url);
            console.log('check render url', response);
            return response.ok; // 'ok' property is true if the response is successful (status in the range 200–299)
        } catch (error) {
            console.error('Error fetching URL:', error);
            return false;
        }
    },
    startRenderCheckInterval() {
        const intervalId = setInterval(() => {
            this.getLogger(this.userId).then(() => {
                const allRendered = this.logger.avatars.every(avatar => avatar.render_url);
                if (allRendered) {
                    this.waitForRender = false;
                    clearInterval(intervalId);
                    console.log("All avatars have been rendered.");
                }
            });
        }, 4000);
    },

    async deleteAvatar(avatar) {
      const response = await fetch(`https://api.avaturn.me/api/v1/users/${this.userId}/avatars/${avatar.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.logger.avatars = this.logger.avatars.filter(avatar_ => avatar_.id !== avatar.id)
        this.updateLogger(this.userId)
        console.log('delete result', data);
        this.getUserAvatars();
    },
    async renderAvatarImage(avatar) {
      const response = await fetch(`https://api.avaturn.me/api/v1/renders/new?format=png&avatar_id=${avatar.id}&scene=common/transparent_half_body`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('render result', data);
    },
    async createNewSession(uid, avatar_id) {
      try {
        this.isLoading = false;
        const response = await fetch('https://api.avaturn.me/api/v1/sessions/new', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_id: uid,
            config: {
              type: "edit_existing",
              avatar_id: avatar_id
            }
          })
        });

        if (!response.ok) {
          this.isLoading = false;
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.sessionId = data.id;
        this.avaturnUrl = data.url;
        this.setViewMode('edit');
        this.isLoading = false;
        console.log('session id', this.viewMode, data);
        // Process the data as needed
      } catch (error) {
        console.error('Error making the API call:', error);
      }
    },
    async createNewUser() {
      try {
        const response = await fetch('https://api.avaturn.me/api/v1/users/new', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        this.userId = data.id;
        await this.createLogger(data.id);
      } catch (error) {
        console.error('Error making the API call:', error);
      }
    },
    toggleAvatar() {
      this.fetch = false;
      this.showAvaturn = !this.showAvaturn;
      this.showScene = !this.showScene;
    },
    getSlug() {
      const path = window.location.pathname;
      const segments = path.split('/');
      console.log('segments', segments, window.location);
      return segments.pop() || segments.pop();
    }
  }
}
</script>


<style>
@import "@/assets/css/main.css";
</style>

