<template>
  <div class="viewer-container" ref="viewContainer">
    <!-- Video element for the camera stream -->
    <video ref="videoElement" class="camera-background" autoplay playsinline></video>

    <!-- Canvas for drawing the pose -->
    <canvas ref="canvasElement" class="pose-canvas"></canvas>

    <!-- Pose detection info -->
    <div v-if="poseDetected" class="pose-info">
      <p>Pose Detected! Drawing body pose on canvas...</p>
    </div>
  </div>
</template>

<script>
import * as posenet from '@tensorflow-models/posenet';
import * as tf from '@tensorflow/tfjs';
import {markRaw, nextTick} from 'vue';

export default {
  name: 'PoseAvatarComponent',
  data() {
    return {
      videoElement: null,
      canvasElement: null,
      poseNetModel: null,
      poseDetected: false,
      backendInitialized: false,
    };
  },
  async mounted() {
    await this.initCameraStream();
  },
  methods: {
    // Initialize the camera stream and load PoseNet model after video is ready
    async initCameraStream() {
      try {
        this.videoElement = this.$refs.videoElement;
        const container = this.$refs.viewContainer;
        const videoWidth = container.clientWidth;
        const videoHeight = container.clientHeight;
        this.videoElement.width = videoWidth;
        this.videoElement.height = videoHeight;
        console.log('vid size', videoWidth, videoHeight);
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: videoWidth },
            height: { ideal: videoHeight },
            facingMode: 'environment'
          }, // Use rear camera for max zoom-out
          audio: false,
        });
        this.videoElement.srcObject = stream;

        // Once the video metadata is loaded, we initialize TensorFlow and PoseNet
        this.videoElement.onloadedmetadata = async () => {
          console.log('Video metadata loaded');
          console.log(`Video Dimensions: ${this.videoElement.videoWidth}x${this.videoElement.videoHeight}`);
          nextTick(async () => {
            await this.initializeBackend();
          });
        };
      } catch (error) {
        console.error('Error accessing the camera: ', error);
      }
    },
    // Initialize the TensorFlow backend and fallback to CPU if needed
    async initializeBackend() {
      try {
        console.log('Initializing TensorFlow.js backend...');
        await tf.setBackend('webgl');
        await tf.ready();
        console.log('WebGL backend initialized.');
        this.backendInitialized = true;
      } catch (error) {
        console.error('Error initializing WebGL backend. Falling back to CPU...', error);
        await tf.setBackend('cpu');
        await tf.ready();
        console.log('CPU backend initialized.');
        this.backendInitialized = true;
      } finally {
        if (this.backendInitialized) {
          await this.initPoseNet();
        } else {
          console.error('Failed to initialize TensorFlow.js backend');
        }
      }
    },
    // Initialize PoseNet model with dynamic video dimensions
    async initPoseNet() {
      const videoWidth = this.videoElement.videoWidth;
      const videoHeight = this.videoElement.videoHeight;

      if (videoWidth === 0 || videoHeight === 0) {
        console.error("Video element dimensions are 0, retrying...");
        return;
      }

      console.log(`Initializing PoseNet with video dimensions: ${videoWidth}x${videoHeight}`);

      // Load PoseNet using the actual video element dimensions
      const model = await posenet.load({
        architecture: 'MobileNetV1',
        outputStride: 16,
        inputResolution: {width: videoWidth, height: videoHeight},
        multiplier: 0.75,
      });

      this.poseNetModel = markRaw(model);
      console.log('PoseNet model loaded successfully:', this.poseNetModel);
      this.detectPose();
    },
    // Real-time pose detection
    async detectPose() {
      if (!this.videoElement || !this.poseNetModel) {
        console.warn("Video or PoseNet model not ready for pose detection.");
        return;
      }

      console.log(`Detecting pose on video of dimensions: ${this.videoElement.videoWidth}x${this.videoElement.videoHeight}`);

      const pose = await this.poseNetModel.estimateSinglePose(this.videoElement, {
        flipHorizontal: false,
      });

      if (pose) {
        console.log('Pose detected:', pose);
        this.updateCanvasWithPose(pose);
        this.poseDetected = true;
      } else {
        this.poseDetected = false;
      }

      requestAnimationFrame(this.detectPose);
    },
    updateCanvasWithPose(pose) {
      this.canvasElement = this.$refs.canvasElement;
      const ctx = this.canvasElement.getContext('2d');

      // Set canvas size to match the video
      this.canvasElement.width = this.videoElement.videoWidth;
      this.canvasElement.height = this.videoElement.videoHeight;

      console.log(`Updating canvas with dimensions: ${this.canvasElement.width}x${this.canvasElement.height}`);

      // Clear the canvas
      ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

      // Draw keypoints and skeleton
      this.drawKeypoints(pose.keypoints, 0.1, ctx);
      this.drawSkeleton(pose.keypoints, 0.1, ctx);
    },
    drawKeypoints(keypoints, minConfidence, ctx) {
      keypoints.forEach((keypoint, index) => {
        if (keypoint.score > minConfidence) {
          const {y, x} = keypoint.position;
          console.log(`Drawing keypoint ${index} at (${x}, ${y}) with confidence ${keypoint.score}`);
          ctx.beginPath();
          ctx.arc(x, y, 5, 0, 2 * Math.PI);
          ctx.fillStyle = 'red';
          ctx.fill();
        } else {
          console.log(`Keypoint ${index} has low confidence: ${keypoint.score}`);
        }
      });
    },
    drawSkeleton(keypoints, minConfidence, ctx) {
      const adjacentKeyPoints = posenet.getAdjacentKeyPoints(keypoints, minConfidence);

      adjacentKeyPoints.forEach((keypoints, index) => {
        console.log(`Drawing skeleton segment ${index} between keypoints:`, keypoints);
        this.drawSegment(keypoints[0].position, keypoints[1].position, ctx);
      });
    },
    drawSegment(p1, p2, ctx) {
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'green';
      ctx.stroke();
    },
  },
};
</script>

<style scoped>
.viewer-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.camera-background {
  position: absolute;
  z-index: -1;
  object-fit: cover;
}

.pose-canvas {
  position: absolute;
}

.pose-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: white;
  border-radius: 5px;
}
</style>
